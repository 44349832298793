import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dumbbell Step Ups 3×12/leg`}</p>
    <p>{`Dumbbell Stiff Leg Deadlifts 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`3-Sled Push trips down & back on turf (+90/45)`}</p>
    <p>{`30-V Ups`}</p>
    <p>{`40-Squats Holding Bumper Plate (45/25)`}</p>
    <p>{`40-Situps`}</p>
    <p>{`50-Jumps Over Bumper Plate (45/25)`}</p>
    <p>{`50-KBS’s (53/35)`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      